import logo from './camera@3x.png';
import './App.css';

function App() {

  const applicationItems = [
    {id: 1, title: 'Resident Android Mobile Application'},
    {id: 2, title: 'Guard Android Mobile Application'},
    {id: 3, title: 'Service Staff Android Mobile Application'},
    {id: 4, title: 'Property Admin Android Mobile Application'},
    {id: 5, title: 'Guard Kiosk Android Mobile Application'},    
    {id: 6, title: 'Service Provider Android Mobile Application'},
  ]

  return (
    <div className="App">
      <div style={{width: '100%', fontSize: 40, color: 'white', height: 100, marginBottom: 30, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: '#151414'}}>
        <div style={{fontWeight: 800}}><p>UG&nbsp;</p></div>STORE
      </div>
      <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'white'}}>
        {
          applicationItems && applicationItems.map((item)=>{
            return(
              <div style={{width: '25%', margin: 35, color: 'lavender', paddingTop: 25, backgroundColor: '#212020', paddingBottom: 40, marginBottom: 20, borderRadius: 10}}>
                <div style={{background: `linear-gradient(45deg,#cc2b5e,#753a88)`, padding: 5, fontWeight: 600}}>{item.title}</div>      
                <div style={{marginTop: 10, fontWeight: 100}}>
                  Uploaded Date : 25 June 2021
                </div>
                <div style={{marginTop: 10, fontWeight: 100}}>
                  Version : 0.0.0
                </div>
                <div>
                  <button style={{backgroundColor: '#CB4335', padding: 10, marginTop: 30, color: 'white', borderRadius: 5, borderWidth: 2, borderColor: '#E6B0AA', paddingLeft: 50, paddingRight: 50}}>Download Now</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default App;
